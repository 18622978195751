/* eslint-disable import/prefer-default-export */

/**
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const elasticLunr = require('elasticlunr');

exports.onClientEntry = async () => {
  // eslint-disable-next-line no-undef
  const response = await fetch(`${__PATH_PREFIX__}/data/searchIndex.json`);
  const fullIndex = await response.json();
  window.searchIndex = elasticLunr.Index.load(fullIndex);
};
