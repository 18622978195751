import { colors } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#2B404E',
    },
    secondary: {
      main: '#558FAB',
    },
    highlight: {
      main: '#A1DBF7',
    },
    white: {
      main: '#FFFFFF',
    },
    grey: {
      300: colors.grey[300],
      500: colors.grey[500],
    },
    background: {
      default: '#FFFFFF',
      secondary: '#F5FAFE',
    },
    text: {
      primary: 'rgba(7, 1, 60, 1.0)', // #07013C
      secondary: 'rgba(7, 1, 60, 0.5)',
      disabled: 'rgba(7, 1, 60, 0.35)',
      hint: 'rgba(7, 1, 60, 0.35)',
      white: '#FFFFFF',
    },
    success: {
      contrastText: '#FFFFFF',
      dark: colors.green[900],
      main: colors.green[600],
      light: colors.green[400],
    },
    info: {
      contrastText: '#FFFFFF',
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400],
    },
    warning: {
      contrastText: '#FFFFFF',
      dark: colors.orange[900],
      main: colors.orange[600],
      light: colors.orange[400],
    },
    error: {
      contrastText: '#FFFFFF',
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400],
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: `${36 / 16}rem`,
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: `${32 / 16}rem`,
      fontWeight: 400,
    },
    h3: {
      fontSize: `${28 / 16}rem`,
      fontWeight: 400,
    },
    h4: {
      fontSize: `${24 / 16}rem`,
      fontWeight: 400,
    },
    h5: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
    h6: {
      fontSize: `${16 / 16}rem`,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
    body1: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
    body2: {
      fontSize: `${16 / 16}rem`,
      fontWeight: 400,
    },
    button: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 700,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#2B404E',
        },
        // We can hide the recaptcha badge per
        // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
        '.grecaptcha-badge': {
          visibility: 'hidden',
        },
        '.fc-toolbar-title': {
          fontFamily: 'Raleway, sans-serif',
          fontSize: `${24 / 16}rem`,
          fontWeight: 400,
        },
        '.fc-toolbar-chunk:last-child': {
          display: 'flex',
        },
        '.fc-event': {
          cursor: 'pointer',
        },
        '.fc-daygrid-event': {
          color: 'rgb(7, 1, 60)',
        },
        '.fc .fc-list-event.fc-event-forced-url:hover a': {
          textDecoration: 'none',
        },
      },
    },
    MuiLink: {
      root: {
        color: '#558FAB',
      },
      underlineHover: {
        '&:hover': {
          color: '#6EA8C4',
          textDecoration: 'none',
        },
      },
    },
    '.MuiTableCell-head': {
      fontWeight: 700,
    },
  },
});
